import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import AllServices from '../components/allServices';
import FeatureCardsSection from '../components/featureCardsSection';
import Wizerunek from '../../assets/svg/wizerunek.svg';
import Zespol from '../../assets/svg/zespol.svg';
import Poufnosc from '../../assets/svg/poufnosc.svg';
import Udostepnianie from '../../assets/svg/udostepnianie.svg';
import Integracja from '../../assets/svg/integracja.svg';
import SEO from '../components/seo';

const NextcloudPage = () => {
  const data = useStaticQuery(graphql`
        query NCImages {
            nextcloud: file(
                relativePath: { eq: "nextcloud-iqcode-wymiana-plikow.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowanc: file(
                relativePath: { eq: "kompleksowa-usluga.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            bezpieczenstwo: file(
                relativePath: { eq: "nextcloud-iqcode-bezpieczenstwo.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            onlyoffice: file(
                relativePath: { eq: "nextcloud-onlyoffice-wdrozenia-w-przegladarce.png" }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          Wdrożenia
          {' '}
          <span>Nextcloud</span>
        </>
              )}
      subtitle="Innowacyjna chmura firmowa"
      img="/img/nextcloud-bg.jpg"
    >
      <SEO title="Wdrożenia i wsparcie techniczne Nextcloud - iqcode Software House" description="Nextcloud to nowoczesna chmura firmowa. Oferujemy kompleksowe wdrożenia oraz wsparcie techniczne oprogramowania Nextcloud." />
      <FeatureSectionLight
        mb0
        title={(
          <>
            <span>Nextcloud</span>
            {' '}
            dla firm
          </>
                  )}
        img={(
          <Img
            alt="Nextcloud platforma wymiany plików"
            fluid={data.nextcloud.childImageSharp.fluid}
          />
                )}
        desc={(
          <>
            <p>
              Nextcloud to nowoczesna platforma wymiany plików
              rozszerzona o funkcjonalność ułatwiającą współpracę
              zespołów wewnątrz przedsiębiorstwa. Stanowi ona
              alternatywę dla klasycznych dysków w chmurze.
              Przeprowadzamy wdrożenia Nextcloud oraz oferujemy
              szkolenia i wsparcie techniczne dla
              gotowych instalacji. Nasze usługi wdrożeniowe obejmują
              analizę potrzeb klienta, projektowanie i wdrażanie
              rozwiązań personalizujących oprogramowanie Nextcloud
              w celu automatyzacji procesów biznesowych przedsiębiorstwa.
            </p>
            <p>
              Oprogramowanie Nextcloud może być utrzymywane w
              chmurze publicznej (u dostawcy takiego jak MS Azure,
              AWS czy GCP) lub na lokalnym serwerze klienta
              (instalacja
              {' '}
              <i>on-premise</i>
              ).
            </p>
          </>
                  )}
      />

      <FeatureCardsSection
        title={(
          <>
            Korzyści
            {' '}
            <span>Nextcloud</span>
          </>
                  )}
        subtitle="W czym Nextcloud wspomaga firmę"
        cards={[
          {
            title: (
              <>
                Udostępnianie
                <br />
                Plików
              </>
            ),
            icon: <Udostepnianie />,
            desc:
                            'Nextcloud pozwala współdzielić pliki z innymi użytkownikami oraz całymi grupami użytkowników, a także wygodnie odbierać duże pliki od klientów.',
          },
          {
            title: (
              <>
                Korzyści
                <br />
                Wizerunkowe
              </>
            ),
            icon: <Wizerunek />,
            desc: (
              <>
                Wdrożona instancja Nextcloud jest sygnowana
                logotypem i kolorystyką przedsiębiorstwa
                klienta, co korzystnie wpływa na wizerunek
                marki.
              </>
            ),
          },
          {
            title: (
              <>
                Praca
                <br />
                Zespołowa
              </>
            ),
            icon: <Zespol />,
            desc:
                            'Liczne rozszerzenia Nextcloud oferują funkcjonalności zintegrowanych kalendarzy, list kontaktów, tablic typu Kanban i list zadań ułatwiając organizację pracy w grupie.',
          },
          {
            title: (
              <>
                Poufność
                <br />
                Danych
              </>
            ),
            icon: <Poufnosc />,
            desc: (
              <>
                Klienci, którym szczególnie zależy na zachowaniu
                poufności danych przechowywanych w Nextcloud
                korzystają z instalacji
                {' '}
                <i>on-premise</i>
                {' '}
                z
                wykorzystaniem własnych serwerów.
              </>
            ),
          },
          {
            title: (
              <>
                Własne
                <br />
                Integracje
              </>
            ),
            icon: <Integracja />,
            desc: (
              <>
                Posiadane przez nas zaplecze programistyczne oraz otwarty
                kod źródłowy Nextcloud pozwalają nam
                na tworzenie niestandardowych integracji Nextcloud z
                innymi systemami w przedsiębiorstwie klienta.
              </>
            ),
          },
        ]}
      />
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Chcesz przetestować
            {' '}
            <span>Nextcloud</span>
            ?
          </h3>
          <Link className="btn" to="/kontakt/">
            Poproś o demo
          </Link>
        </div>
      </div>

      <FeatureSectionLight
        mb0
        title={(
          <>
            Oprogramowanie biurowe
            {' '}
            <span>OnlyOffice</span>
          </>
                  )}
        img={(
          <Img
            alt="pakiet biurowy OnlyOffice"
            fluid={data.onlyoffice.childImageSharp.fluid}
          />
                )}
        desc={(
          <>
            <p>
              We wdrażanych przez nas instancjach Nextcloud, na
              życzenie klienta przeprowadzamy integrację z
              pakietem biurowym OnlyOffice, który umożliwia edycję
              dokumentów tekstowych, arkuszy kalkulacyjnych oraz
              prezentacji w przeglądarce.
            </p>
            <p>
              OnlyOffice zachowuje pełną kompatybilność z
              formatami oprogramowania MS Excel, Word i
              PowerPoint, co czyni go pełnowartościowym
              zamiennikiem pakietu MS Office.
            </p>
          </>
                  )}
      />

      <FeatureSectionLight
        invert
        title={<>Bezpieczeństwo</>}
        img={(
          <Img
            alt="test bezpieczeństwa Nextcloud"
            fluid={data.bezpieczenstwo.childImageSharp.fluid}
          />
                )}
        desc={(
          <>
            <p>
              Bezpieczeństwo instancji Nextcloud znajdujących się pod naszą opieką
              jest naszym priorytetem. Przeprowadzamy regularne aktualizacje oprogramowania
              oraz wdrażamy liczne rozwiązania podwyższające poziom bezpieczeństwa administrowanych
              przez nas instalacji. Posiadamy odpowiednie procedury
              {' '}
              <i>disaster recovery</i>
              {' '}
              oraz
              {' '}
              <i>incident response</i>
              {' '}
              umożliwiające minimalizację szkód wyrządzonych przez
              poważne awarie infrastruktury lub incydenty związane z nieuprawnionym dostępem do
              chronionych danych.
            </p>
            <p>
              Wszystkie nasze wdrożenia Nextcloud otrzymują
              najwyższą ocenę
              {' '}
              <a
                rel="nofollow"
                href="https://scan.nextcloud.com/"
              >
                oficjalnego narzędzia do badania instancji
                nextcloud
              </a>
              .
            </p>
          </>
                  )}
      />

      <div className="seo-icons overlay-holder bg-dark">
        <div className="diagonal-overlay-light" />
        <div className="row rel">
          <h2
            style={{ marginBottom: '30px', marginTop: '20px' }}
            className="promobox"
          >
            Najważniejsze cechy
            {' '}
            <span className="p">naszej usługi</span>
          </h2>
          <div className="col s12 m6 l6 xl3">
            <div className="seo-card">
              <div className="seo-icon">
                <img
                  src="/img/wsparcie-techniczne-nextcloud.svg"
                  alt="wsparcie techniczne Nextcloud"
                />
              </div>
              <h3 className="seo-icons-desc">
                Bezkompromisowe wsparcie techniczne
              </h3>
              <p>
                Nasza pomoc jest dostępna mailowo, przez telefon
                lub z poziomu panelu wsparcia zintegrowanego z Nextcloud. Gwarantowany czas
                obsługi zgłoszenia, albo zwrot pieniędzy za dany
                okres rozliczeniowy.
              </p>
            </div>
          </div>
          <div className="col s12 m6 l6 xl3">
            <div className="seo-card">
              <div className="seo-icon">
                <img
                  src="/img/gwarancja-dostepnosci-serwera.svg"
                  alt="gwarancja dostępności instancji Nextcloud"
                />
              </div>
              <h3 className="seo-icons-desc">
                Wysoka dostępność usługi
              </h3>
              <p>
                Dostępność naszych serwerów i poprawne działanie
                usług jest automatycznie monitorowane 24 godziny
                na dobę przez 7 dni w tygodniu, co pozwala nam
                eliminować usterki zanim klient zdąży je
                zauważyć.
              </p>
            </div>
          </div>
          <div className="col s12 m6 l6 xl3">
            <div className="seo-card">
              <div className="seo-icon">
                <img
                  src="/img/wydajny-hosting.svg"
                  alt="wydajny hosting Nextcloud"
                />
              </div>
              <h3 className="seo-icons-desc">
                Wydajna infrastruktura
              </h3>
              <p>
                Oferujemy w pełni zarządzane, wydajne maszyny
                wirtualne z gwarantowanymi zasobami procesora,
                pamięci RAM oraz powierzchni dyskowej (dotyczy
                wdrożenia Nextcloud w chmurze publicznej).
              </p>
            </div>
          </div>
          <div className="col s12 m6 l6 xl3">
            <div className="seo-card">
              <div className="seo-icon">
                <img
                  src="/img/bezpieczenstwo-danych.svg"
                  alt="bezpieczeństwo danych Nextcloud"
                />
              </div>
              <h3 className="seo-icons-desc">
                Bezpieczeństwo danych
              </h3>
              <p>
                W przypadku instalacji Nextcloud na
                infrastrukturze dostarczanej przez iqcode,
                wykonujemy pełną kopię zapasową serwera co 24
                godziny, którą następnie przechowujemy przez 7
                dni w niezależnym geograficznie centrum danych.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FeatureSectionLight
        mb0
        title={(
          <>
            <span>Kompleksowa </span>
            Usługa
          </>
                  )}
        img={(
          <div style={{ padding: '10%' }}>
            <Img
              alt="kompleksowe usługi serwerowe"
              fluid={data.kompleksowanc.childImageSharp.fluid}
              imgStyle={{ width: 'auto' }}
            />
          </div>
                  )}
        desc={(
          <>
            <p>
              Przeprowadzamy integracje platformy Nextcloud z
              innym oprogramowaniem dla firm takim jak systemy
              CRM/ERP, systemy logowania SSO oraz aplikacje helpdesk.
              Do naszego zespołu należą programiści posiadający doświadczenie
              w tworzeniu niestandardowych aplikacji Nextcloud, dzięki
              czemu możliwości naszych klientów nie są ograniczane
              przez funkcjonalność, którą Nextcloud oferuje w standardzie.
            </p>
            <p>
              Szeroki wachlarz świadczonych przez nas usług oraz
              nastawienie na długotrwałą współpracę z klientem
              umożliwia nam prowadzenie kompleksowej obsługi
              informatycznej przedsiębiorstw.
            </p>
          </>
                  )}
        link="Porozmawiajmy"
        to="/kontakt/"
      />
      <AllServices />
    </SubLayout>
  );
};

export default NextcloudPage;
